<template>
  <VContainer class="menu-section-cover" fill-height hidden-xs-only>
    <VLayout
      class="cover-content"
      column
      justify-start
      fill-height
      text-xs-center
    >
      <VFlex shrink mb-3>
        <img src="@assets/images/db-logo-menu.svg" alt="DB Logo" width="32%" />
      </VFlex>
      <VFlex shrink class="tagline blanch--font">
        <VLayout justify-center>
          <VFlex xs10>
            <VLayout align-center>
              <VFlex class="green-hr" grow mr-3></VFlex>
              <VFlex shrink>
                Cafe & Juice Bar
              </VFlex>
              <VFlex class="green-hr" grow ml-3></VFlex>
            </VLayout>
          </VFlex>
        </VLayout>
      </VFlex>
      <VFlex shrink class="blurb">
        <VLayout fill-height align-center justify-center row>
          <VFlex xs7 text-xs-justify>
            <h3 class="r-headline blurb--title pb-3 mb-3 d-inline-block"
              >Our Mission</h3
            >
            <p class="asap--font blurb--desc"
              >Our ingredients speak for themselves. Our menu is carefully
              crafted to promote and enhance the well-being of our city. Dining
              at The Daily Beet will energize your body, fuel your mind, and
              enrich your daily life.</p
            >
          </VFlex>
        </VLayout>
      </VFlex>
    </VLayout>
  </VContainer>
</template>

<script>
export default {
  name: 'MenuSectionCover',
  data() {
    return {}
  },
}
</script>

<style lang="scss">
@import '@design';

.tagline {
  font-size: 3rem;
}

.green-hr {
  height: 1px;
  background: #2e7339;
}

.blurb {
  margin-top: 8vw;
}

.blurb--title {
  font-size: 0.9rem;
  border-bottom: 2px solid $color-db-purple;
}
.blurb--desc {
  font-size: 0.9rem;
  line-height: 1.7;
}
</style>
